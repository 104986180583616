import { NextSeo } from "next-seo";
import dynamic from "next/dynamic";

const Hero = dynamic(() => import("@/components/home/Hero"));
const HowItWorks = dynamic(() => import("@/components/home/HowItWorks"));
const Features = dynamic(() => import("@/components/home/Features"));
const Statistics = dynamic(() => import("@/components/home/Statistics"));
const Testimonials = dynamic(() => import("@/components/home/Testimonials"));
const FAQ = dynamic(() => import("@/components/home/FAQ"));
const CTA = dynamic(() => import("@/components/home/CTA"));
const Footer = dynamic(() => import("@/components/shared/Footer"));

export default function Home() {
  return (
    <div className="bg-white">
      <NextSeo
        title="Crayo AI | Viral clips in seconds."
        description="Your gateway to creating viral TikTok clips in seconds! Harness the power of AI to turn your ideas into captivating videos effortlessly."
      />
      <Hero />
      <HowItWorks />
      <Features />
      {/* <Statistics/> */}
      <Testimonials />
      <FAQ />
      <CTA />
      <Footer />
    </div>
  );
}
